import { useEffect, useContext, createContext, useState } from "react";
import { auth, db } from "../firebase";

import { onAuthStateChanged, signOut } from "firebase/auth";
import { getDoc, setDoc, doc, serverTimestamp } from "firebase/firestore/lite";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authChangeHandler);
    return () => unsubscribe();
  });

  const logout = () => {
    signOut(auth);
  };

  const getUserDataFromDatabase = (uid) => {
    return getDoc(doc(db, "users", uid))
      .then((e) => {
        if (e.exists())
          return {
            status: true,
            data: e.data(),
          };

        return {
          status: false,
          data: null,
        };
      })
      .catch((e) => {
        return { status: false, data: null };
      });
  };

  const addUserToDatabase = async ({ name, email, uid, provider }) => {
    const first_name = name?.includes(" ") ? name?.split(" ")[0] : name;
    const last_name = name?.includes(" ") ? name?.split(" ")[1] : "";

    return setDoc(doc(db, "users", uid), {
      uid: uid,
      first_name: first_name,
      last_name: last_name,
      email: email,
      provider: provider,
      created_at: serverTimestamp(),
      resumes: [],
    }).then((e) => e);
  };

  const authChangeHandler = async (user) => {
    if (user !== null && user?.uid !== currentUser?.uid) {
      const authProvider = user?.providerData[0];

      /* Handle OAuth */
      // if (authProvider.providerId !== "password") {
      let _user = await getUserDataFromDatabase(user.uid);

      if (_user.status) {
        setCurrentUser({
          name: _user.data.first_name,
          uid: _user.data.uid,
          email: _user.data.email,
          provider: _user.data.provider,
        });
        setIsLoading(false);
      } else if (!_user.status) {
        /* If user does not exist in firestore */
        await addUserToDatabase({
          name: authProvider.displayName ?? authProvider.email?.split("@")[0],
          email: authProvider.email,
          provider: authProvider.providerId,
          uid: user.uid,
        })
          .then((e) => {
            setCurrentUser({
              name:
                authProvider.displayName ?? authProvider.email?.split("@")[0],
              uid: user.uid,
              email: authProvider.email,
              provider: authProvider.providerId,
            });
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            return;
          });
      }
    } else if (
      user === null &&
      (window.location.pathname.includes("/dashboard") ||
        window.location.pathname.includes("/builder"))
    ) {
      window.location.href = "/";
    }

    setIsLoading(false);
  };

  return (
    <AuthContext.Provider value={{ currentUser, logout }}>
      {isLoading && (
        <div
          style={{ display: "grid", placeItems: "center", minHeight: "100vh" }}
        >
          Loading
        </div>
      )}
      {!isLoading && props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  return {
    ...auth,
    isAuthenticated: auth.currentUser != null,
    changeUser: (e) => auth.setCurrentUser(e),
  };
};

export default AuthContextProvider;
