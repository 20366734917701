import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyDFrTbOVDQHeK2m142XsA360q1Bwc3ieRQ",
  authDomain: "devbook-ishandeveloper.firebaseapp.com",
  databaseURL: "https://devbook-ishandeveloper.firebaseio.com",
  projectId: "devbook-ishandeveloper",
  storageBucket: "devbook-ishandeveloper.appspot.com",
  messagingSenderId: "293410621141",
  appId: "1:293410621141:web:d810c666ce74bd308f445c",
  measurementId: "G-TTHRW623MW",
});

export const authentication = app;
export const auth = getAuth(app);
export default app;
export const db = getFirestore(app);
