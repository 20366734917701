import React from "react";
import ReactDOM from "react-dom";

import {
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { auth } from "./firebase";

import { default as AuthProvider, useAuth } from "./contexts/auth";

const OAuthHandler = async (service) => {
  let provider;

  switch (service) {
    case "google":
      provider = new GoogleAuthProvider();
      break;

    case "github":
      provider = new GithubAuthProvider();
      break;

    default:
      return null;
  }
  await signInWithPopup(auth, provider).catch((e) => {
    console.error("ERROR", e);
  });
};

const Login = () => {
  return (
    <>
      <button onClick={() => OAuthHandler("google")}>LOGIN WITH GOOGLE</button>
      <button onClick={() => OAuthHandler("github")}>LOGIN WITH GITHUB</button>
    </>
  );
};

const App = () => {
  const { currentUser } = useAuth();

  return (
    <div>
      {JSON.stringify(currentUser)}

      <Login />
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
